import React from 'react'
import ReactPaginate from 'react-paginate'
import styles from './Pagination.module.scss'
import { useSelector } from 'react-redux'
import { setCurrentPage } from '../../redux/slices/FilterSlice/slice'
import { useAppDispatch } from '../../redux/store'
import { currentPageSelector } from '../../redux/slices/FilterSlice/selectors'
import { allItemsSelector } from '../../redux/slices/PizzasSlice/selectors'

export const Pagination: React.FC = () => {
	const currentPage = useSelector(currentPageSelector)
	const allItems = useSelector(allItemsSelector);
	const dispatch = useAppDispatch()
	const [pageCount, setPageCount] = React.useState(0)

	React.useEffect(() => {
		setPageCount(Math.ceil(allItems.length / 4))
	}, [allItems])


	return (
		<ReactPaginate
			className={styles.paginate}
        breakLabel="..."
        nextLabel=">"
        onPageChange={(event) => dispatch(setCurrentPage(event.selected + 1))}
        pageRangeDisplayed={5}
        pageCount={pageCount ? pageCount : 1}
				forcePage={currentPage - 1}
        previousLabel="<"
      />
	)
}
