import React from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'

export const NotFoundBlock = () => {
	return (
		<div className={styles.root}>
			<h1 >
				<span>😕</span>
				<br/>
				Ничего не найдено
			</h1>
			<p className={styles.descr}>К сожалению, данная страница отсутствует в нашем магазине</p>
			<Link to="/" className="button button--black">
				<span>Вернуться на главную</span>
			</Link>
		</div>

	)
}

