import './scss/app.scss';
import Home from './pages/Home';
import { Routes, Route } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import React from 'react';
import { useSelector } from 'react-redux';
import { setMounted } from './redux/slices/FilterSlice/slice';
import { useAppDispatch } from './redux/store';
import { FilterSelector } from './redux/slices/FilterSlice/selectors';

const Cart = React.lazy(() => import(/* webpackChunkName: "Cart"*/ './pages/Cart'))
const NotFound = React.lazy(() => import(/* webpackChunkName: "NotFound"*/  './pages/NotFound'))
const FullPizza = React.lazy(() => import(/* webpackChunkName: "FullPizza"*/  './pages/FullPizza'))


function App() {
  // https://62a0c46c202ceef70876330d.mockapi.io/pizzas
	const {isMounted} = useSelector(FilterSelector)
	const dispatch = useAppDispatch()

	React.useEffect(() => {
		if (!isMounted) {
			dispatch(setMounted(true))
		}

		// eslint-disable-next-line
	}, [])

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route element={<Home />} path="/" />
        <Route element={
					<React.Suspense fallback={<h1>Загрузка...</h1>}>
						<Cart />
					</React.Suspense>
				} path="/cart" />
        <Route element={
					<React.Suspense fallback={<h1>Загрузка...</h1>}>
						<FullPizza />
					</React.Suspense>
				} path="/pizza/:id" />
        <Route element={
					<React.Suspense fallback={<h1>Загрузка...</h1>}>
						<NotFound />
					</React.Suspense>
				} path="*" />
      </Route>
    </Routes>
  );
}

export default App;
