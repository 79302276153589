export enum SortPropertyEnum {
	RATING_DESC = 'rating',
	RATING_ASC = '-rating',
	TITLE_DESC = '-title',
	TITLE_ASC = 'title',
	PRICE_DESC = 'price',
	PRICE_ASC = '-price',
}

export type TSortProperty = {
	name: string;
	sortProperty: SortPropertyEnum;
}

export interface IFilterSliceState {
	categoryId: number;
	currentPage: number;
	sort: TSortProperty;
	searchQuery: string;
	isMounted: boolean;
}

export type TSearchFilterParams = {
	sortProperty: string;
	categoryId: number;
	currentPage: number;
}