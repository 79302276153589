import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilterSliceState, SortPropertyEnum, TSortProperty } from './types';

const initialState: IFilterSliceState = {
  categoryId: 0,
  currentPage: 1,
  sort: {
    name: 'популярности (DESC)',
    sortProperty: SortPropertyEnum.RATING_DESC,
  },
  searchQuery: '',
	isMounted: false
};

const FilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setCategoryId(state, action: PayloadAction<number>) {
      state.categoryId = action.payload;
    },
    setActiveSort(state, action: PayloadAction<TSortProperty>) {
      state.sort = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    setFilters(state, action: PayloadAction<IFilterSliceState>) {
			state.currentPage = Number(action.payload.currentPage);
			state.categoryId = Number(action.payload.categoryId);
			state.sort = action.payload.sort;
    },
		setMounted(state, action: PayloadAction<boolean>) {
			state.isMounted = action.payload
		}
  },
});

export const { setCategoryId, setActiveSort, setCurrentPage, setFilters, setSearchQuery, setMounted } =
  FilterSlice.actions;

export default FilterSlice.reducer;
