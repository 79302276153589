import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPizzas } from './asyncActions';
import { IPizzasSliceState, Status, TFetchReturned, TPizza } from './types';


const initialState: IPizzasSliceState = {
  allItems: [],
  items: [],
  status: Status.LOADING,
};

const PizzasSlice = createSlice({
  name: 'pizzas',
  initialState,
  reducers: {
    setItems(state, action: PayloadAction<TPizza[]>) {
      state.items = action.payload;
    },

    setAllItems(state, action: PayloadAction<TPizza[]>) {
      state.allItems = action.payload;
    },
  },
	extraReducers: (builder) => {
		builder.addCase(fetchPizzas.pending, (state) => {
			state.status = Status.LOADING;
			state.items = [];
		})

		builder.addCase(fetchPizzas.fulfilled, (state, action: PayloadAction<TFetchReturned>) => {
			state.items = action.payload.data;
			state.allItems = action.payload.allData;
			state.status = Status.SUCCESS;
		})

		builder.addCase(fetchPizzas.rejected, (state) => {
			state.status = Status.ERROR;
			state.items = [];
			state.allItems = [];
		})
	}
	// Если не юзаешь TypeScript
  // extraReducers: {
  //   [fetchPizzas.pending]: (state) => {
  //     state.status = 'loading';
  //     state.items = [];
  //   },
  //   [fetchPizzas.fulfilled]: (state, action) => {
  //     state.items = action.payload.data;
  //     state.allItems = action.payload.allData;
  //     state.status = 'success';
  //   },
  //   [fetchPizzas.rejected]: (state) => {
  //     state.status = 'error';
  //     state.items = [];
  //     state.allItems = [];
  //   },
  // },
});

export const { setItems, setAllItems } = PizzasSlice.actions;

export default PizzasSlice.reducer;
