import React from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../redux/store'
import { Link } from 'react-router-dom';
import {addItem} from '../../redux/slices/CartSlice/slice'
import { cartItemsSelector } from '../../redux/slices/CartSlice/selectors';
import { TCartItem } from '../../redux/slices/CartSlice/types';

type PizzaProps = {
	id: string;
	title: string;
	price: number;
	imageUrl: string;
	sizes: number[];
	types: number[];
}


export const PizzaBlock: React.FC<PizzaProps> = React.memo(({id, title, price, imageUrl, sizes, types}) => {
	const [cartItemCount, setCartItemCount] = React.useState(0);
	const dispatch = useAppDispatch()
	const [isType, setIsType] = React.useState(0)
	const [isSize, setIsSize] = React.useState(0)
	const [plusSizePrice, setPlusSizePrice] = React.useState(0)
	const [plusTypePrice, setPlusTypePrice] = React.useState(0)
	const [totalPrice, setTotalPrice] = React.useState(price)

	const cartItem: TCartItem[] = useSelector(cartItemsSelector)

	React.useEffect(() => {
		const filterCartItem = cartItem.filter(obj => obj.id === id);
		const totalCount = filterCartItem.reduce((sum, item) => sum + item.count, 0)
		setCartItemCount(totalCount)
		// eslint-disable-next-line
	}, [cartItem])

	React.useEffect(() => {
		isType === 1 ? setPlusTypePrice(100) : setPlusTypePrice(0)

		if (isSize === 1) {
			setPlusSizePrice(50)
		} else if (isSize === 2) {
			setPlusSizePrice(100)
		} else {
			setPlusSizePrice(0)
		}

		setTotalPrice(price + plusSizePrice + plusTypePrice)

		// eslint-disable-next-line
	}, [isType, isSize, plusTypePrice, plusSizePrice])


	const onClickAdd = () => {
		const item: TCartItem = {
			id,
			title,
			size: sizes[isSize],
			type: isType === 0 ? 'тонкое' : 'традиционное',
			price: totalPrice,
			imageUrl,
			count: 0
		}

		dispatch(addItem(item))
	}

	const setPizzaOptions = (index: number, callback: (index: number) => void) => {
		callback(index);
	}

	return (
		<div className="pizza-block">
							<Link to={`/pizza/${id}`}>
								<img
									className="pizza-block__image"
									src={imageUrl}
									alt="Pizza"
								/>
							</Link>
							<Link to={`/pizza/${id}`}>
             	 <h4 className="pizza-block__title">{title}</h4>
							</Link>
              <div className="pizza-block__selector">
                <ul>
									{
										types.map((type, index) =>
											<li key={index} onClick={() => setPizzaOptions(index, setIsType)} className={isType === index ? 'active' : ''}>
												{type === 0 ? 'тонкое' : 'традиционное'}
											</li>
										)
									}
                </ul>
                <ul>
									{
										sizes.map((size, index) =>
											<li key={index} onClick={() => setPizzaOptions(index, setIsSize)} className={isSize === index ? 'active' : ''}>
												{size} см.
											</li>
										)
									}
                </ul>
              </div>
              <div className="pizza-block__bottom">
                <div className="pizza-block__price">{totalPrice} ₽</div>
                <button onClick={onClickAdd} className="button button--outline button--add">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8 4.8H7.2V1.2C7.2 0.5373 6.6627 0 6 0C5.3373 0 4.8 0.5373 4.8 1.2V4.8H1.2C0.5373 4.8 0 5.3373 0 6C0 6.6627 0.5373 7.2 1.2 7.2H4.8V10.8C4.8 11.4627 5.3373 12 6 12C6.6627 12 7.2 11.4627 7.2 10.8V7.2H10.8C11.4627 7.2 12 6.6627 12 6C12 5.3373 11.4627 4.8 10.8 4.8Z"
                      fill="white"
                    />
                  </svg>
                  <span>Добавить</span>
                  {cartItemCount > 0 && <i>{cartItemCount}</i>}
                </button>
              </div>
            </div>
	)
})