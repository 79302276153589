import React from 'react'
import style from './Search.module.scss'
import debounce from 'lodash.debounce'
import { useSelector } from 'react-redux'
import { setCategoryId, setSearchQuery } from '../../redux/slices/FilterSlice/slice'
import { useAppDispatch } from '../../redux/store'
import { FilterSelector } from '../../redux/slices/FilterSlice/selectors'

export const Search: React.FC = () => {
	const [searchValue, setSearchValue] = React.useState('')
	const {searchQuery, categoryId} = useSelector(FilterSelector)
	const dispatch = useAppDispatch()
	const inputRef = React.useRef<HTMLInputElement>(null)

	React.useEffect(() => {
		if (categoryId !== 0) {
			dispatch(setSearchQuery(''))
			setSearchValue('')
		}
				// eslint-disable-next-line
	}, [categoryId])

	const onClickClear = () => {
		setSearchValue('');
		dispatch(setSearchQuery(''));
		inputRef.current?.focus();
	}
// eslint-disable-next-line
	const updateInputValue = React.useCallback(
		debounce((str) => {
			dispatch(setSearchQuery(str));
			dispatch(setCategoryId(0))
		}, 350),
		[]
	)

	const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value)
		updateInputValue(e.target.value)
	}

	return (
		<div className={style.root}>
			<svg className={style.icon} enableBackground="new 0 0 32 32" id="Editable-line" version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><circle cx="14" cy="14" fill="none" id="XMLID_42_" r="9" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/><line fill="none" id="XMLID_44_" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" x1="27" x2="20.366" y1="27" y2="20.366"/></svg>

			<input ref={inputRef} value={searchValue} onChange={onChangeInput} className={style.input} placeholder='Поиск пиццы...' type='text'/>
			{ searchQuery && (<svg onClick={() => onClickClear()} className={style.clearIcon} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/></svg>)}
		</div>
	)
}