import { configureStore } from '@reduxjs/toolkit';
import FilterReducer from './slices/FilterSlice/slice';
import cart from './slices/CartSlice/slice';
import pizzas from './slices/PizzasSlice/slice';
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: {
    FilterReducer,
    cart,
    pizzas,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
