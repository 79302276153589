import React from "react"
import ContentLoader from "react-content-loader"

export const PizzaBlockLoader: React.FC = () => (
  <ContentLoader
		className="pizza-block"
    speed={2}
    width={350}
    height={550}
    viewBox="0 0 350 550"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle cx="175" cy="165" r="165" />
    <rect x="0" y="350" rx="10" ry="10" width="350" height="27" />
    <rect x="0" y="397" rx="10" ry="10" width="350" height="88" />
    <rect x="0" y="512" rx="10" ry="10" width="90" height="27" />
    <rect x="192" y="505" rx="30" ry="30" width="152" height="45" />
  </ContentLoader>
)

