export type TFetchParams = {
	categoryId: number;
	activeSort: 'rating' | 'title' | 'price' | '-rating' | '-title' | '-price';
	searchQuery: string;
	currentPage: number;
}

export type TFetchReturned = {
	data: TPizza[];
	allData: TPizza[];
}

export enum Status {
	LOADING = 'loading',
	SUCCESS = 'success',
	ERROR = 'error'
}

export type TPizza = {
	id: string;
	title: string;
	price: number;
	imageUrl: string;
	size: number;
	type: string;
}

export interface IPizzasSliceState {
	items: TPizza[];
	allItems: TPizza[];
	status: Status;
}