import React from 'react'
import qs from 'qs'
import {useNavigate} from 'react-router-dom'
import { sortItem } from '../components/Sort';
import {Categories, PizzaBlock, PizzaBlockLoader, Pagination, Sort} from '../components'
import { useSelector } from 'react-redux'
import { setCurrentPage, setFilters } from '../redux/slices/FilterSlice/slice';
import { useAppDispatch } from '../redux/store';
import { PizzasSelector } from '../redux/slices/PizzasSlice/selectors';
import { FilterSelector } from '../redux/slices/FilterSlice/selectors';
import { TSearchFilterParams } from '../redux/slices/FilterSlice/types';
import { fetchPizzas } from '../redux/slices/PizzasSlice/asyncActions';

const Home: React.FC = () => {
	const {categoryId, sort, currentPage, searchQuery, isMounted} = useSelector(FilterSelector)
	const {items, allItems, status} = useSelector(PizzasSelector)
	const activeSort = sort.sortProperty;
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const isSearch = React.useRef(false)

	const pizzas = items.map((item: any, i: number) =>
		<PizzaBlock key={item.id} {...item} />
	)

	const getPizzas = async () => {
		dispatch(fetchPizzas({
			categoryId,
			activeSort,
			searchQuery,
			currentPage
		}))
	}

	// Если изменили параметры и был первый рендер
  React.useEffect(() => {
    if (isMounted) {
      const queryString = qs.stringify({
        sortProperty: activeSort,
        categoryId,
        currentPage,
      });
      navigate(`?${queryString}`);
    }
			// eslint-disable-next-line
  }, [categoryId, activeSort, currentPage]);

  // Если был первый рендер, то проверяем URl-параметры и сохраняем в редуксе
  React.useEffect(() => {
    if (window.location.search) {
      let params = qs.parse(window.location.search.substring(1)) as unknown as TSearchFilterParams;
      const sort = sortItem.find((obj) => obj.sortProperty === params.sortProperty);

			dispatch(
				setFilters({
					searchQuery,
					categoryId: Number(params.categoryId),
					currentPage: params.currentPage,
					sort: sort || sortItem[0],
					isMounted
				}),
			);

      isSearch.current = true;

    }
		// eslint-disable-next-line
  }, []);

  // Если был первый рендер, то запрашиваем пиццы
  React.useEffect(() => {

		if (categoryId === 0) {
			getPizzas();
		}

    if (!isSearch.current && categoryId !== 0) {
      getPizzas();
    }

    isSearch.current = false;
		// eslint-disable-next-line
  }, [categoryId, activeSort, searchQuery, currentPage]);

	React.useEffect(() => {
		if (allItems.length <= 4) {
			dispatch(setCurrentPage(1))
		}
		// eslint-disable-next-line
	}, [activeSort, categoryId, searchQuery, allItems])

	return (
		<div className='container'>
			<div className="content__top">
				<Categories />
				<Sort activeSort={sort}/>
			</div>
			{
				status === 'error'
				?
				<div className='content__error-info'>
					<h3>Произошла ошибка 😕</h3>
					<p>Не удалось загрузить пиццы. Попробуйте повторить попытку позже.</p>
				</div>
				:
				(
					status === 'loading'
						?
						<>
							<h2 className="content__title">Все пиццы</h2>
							<div className="content__items">
								{[...new Array(4)].map((_, index) => <PizzaBlockLoader key={index} />)}
							</div>
						</>
						: pizzas.length
							?
							<>
								<h2 className="content__title">Все пиццы</h2>
								<div className="content__items">
									{pizzas}
								</div>
							</>
							:
								<div className='content__error-info'>
									<h3>Ничего не найдено 😕</h3>
								</div>

				)
			}

			{pizzas.length && allItems.length > 4 ? <Pagination/> : null}
		</div>
	)
}

export default Home