import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { TCartItem } from "../CartSlice/types";
import { TFetchParams, TFetchReturned, TPizza } from "./types";

export const fetchPizzas = createAsyncThunk<TFetchReturned, TFetchParams>('pizzas/fetchPizzasStatus', async (params) => {
  const { categoryId, activeSort, searchQuery, currentPage } = params;
  const allRes = await axios.get<TCartItem[]>(
    `https://62a0c46c202ceef70876330d.mockapi.io/pizzas?${
      categoryId > 0 ? `category=${categoryId}` : ''
    }&sortBy=${activeSort.replace('-', '')}&order=${
      activeSort.includes('-') ? 'asc' : 'desc'
    }&search=${searchQuery ? searchQuery : ''}`,
  );
  const { data } = await axios.get<TCartItem[]>(
    `https://62a0c46c202ceef70876330d.mockapi.io/pizzas?limit=4&page=${currentPage}&${
      categoryId > 0 ? `category=${categoryId}` : ''
    }&sortBy=${activeSort.replace('-', '')}&order=${
      activeSort.includes('-') ? 'asc' : 'desc'
    }&search=${searchQuery ? searchQuery : ''}`,
  );

  const allData:TPizza[] = allRes.data;
  return { data, allData };
});