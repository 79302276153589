import React from 'react'
import { useSelector } from 'react-redux'
import { categorySelector } from '../redux/slices/FilterSlice/selectors'
import { setCategoryId } from '../redux/slices/FilterSlice/slice'
import { useAppDispatch } from '../redux/store'

const categories = [
	'Все',
	'Мясные',
	'Вегетарианские',
	'Гриль',
	'Острые',
	'Закрытые'
]

export const Categories: React.FC = React.memo(() => {
	const activeCategory = useSelector(categorySelector)
	const dispatch = useAppDispatch()

	const onClickActiveCategory = (index: number) => {
		dispatch(setCategoryId(index));
		// eslint-disable-next-line
	}

	return (
		<div className="categories">
      <ul>
				{
					categories.map((category, index) =>
						<li key={index} onClick={() => onClickActiveCategory(index)} className={activeCategory === index ? 'active' : ''}>{category}</li>
					)
				}
      </ul>
    </div>
	)
})

